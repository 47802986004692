<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="propertiesPaymentsProperties"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>

          <el-table-column
            :label="$t('PROPERTIES_PAYMENTS.AMOUNT')"
            prop="date"
            sortable="custom"
            min-width="120"
          >
            <template v-slot="{ row }">
              {{ $formatCurrency(row.amount) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('PROPERTIES_PAYMENTS.ATTENTION')"
            prop="date"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ row.propertiesPayment.attention }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('PROPERTIES_PAYMENTS.SOURCE')"
            prop="date"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{
                $t(
                  `PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${row.propertiesPayment.source}`
                )
              }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('PROPERTIES.PAYMENT_RECEIVED_BY')"
            prop="created_by"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{
                `${row.propertiesPayment.createdBy?.firstname} ${row.propertiesPayment.createdBy?.lastname}`
              }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('PROPERTY_TRANSACTIONS.TRANSACTION_DATE')"
            min-width="220"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.propertiesPayment.date) }}
            </template>
          </el-table-column>

          <el-table-column min-width="100">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_PROPERTIES)"
              >
                <a
                  type="text"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <router-link
                    :to="{
                      name: `List PropertiesPayments`,
                      query: {
                        id: row.propertiesPayment.id,
                        action: QUERY_ACTIONS_VIEW,
                      },
                    }"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </router-link>
                </a>
              </el-tooltip>
              <el-tooltip
                :content="$t('PROPERTIES.PRINT_RECEIPT')"
                placement="top"
              >
                <a
                  type="text"
                  @click="printOne(row.propertiesPayment.id,row.id)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-file"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import "flatpickr/dist/flatpickr.css";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import swal from "sweetalert2";

export default {
  name: "properties-payment-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterProperty: {
      type: String,
      default: null,
      description: "property id",
    },
    filterPropertiesPayment: {
      type: String,
      default: null,
      description: "property id",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: null,
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      propertiesPaymentsProperties: [],
      loading: true,
      QUERY_ACTIONS_VIEW,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterProperty: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterPropertiesPayment: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async printOne(paymentId, propertyId) {
      this.loading = true;
      swal.fire({
        title: this.$t("COMMON.LOADING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "propertiesPayments/printOne",
          paymentId,
          propertyId
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
        this.loading = false;
      } catch (error) {
        if (error.response) {
          if (error?.response?.status === 404) {
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
        }
        swal.close();
        this.loading = false;
      }
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "property,propertiesPayment,propertiesPayment.createdBy",
        };

        if (this.filterProperty) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              property: this.filterProperty,
            },
          };
        }

        await this.$store.dispatch("propertiesPaymentsProperties/list", params);
        this.propertiesPaymentsProperties =
          this.$store.getters["propertiesPaymentsProperties/list"];
        this.total =
          this.$store.getters["propertiesPaymentsProperties/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
