<template>
  <div class="container-fluid">
    <property-form
      :loading="loading"
      :propertyData="property"
      :formErrors="formErrors"
      @propertySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultProperty from "../defaultProperty";
import PropertyForm from "../partials/PropertyForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PropertyForm,
  },

  mixins: [alertLeave, requestErrorMixin],

  data() {
    const property = cloneDeep(defaultProperty);
    return {
      property: property,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    async handleSubmit(property) {
      this.loading = true;
      const propertyData = cloneDeep(property);

      try {
        await this.$store.dispatch("properties/add", propertyData);
        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES.PROPERTY_ADDED"),
        });
        const property = await this.$store.getters["properties/property"];
        this.$emit("onViewProperty", property, true);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else if (error.response.status === 400) {
          this.formErrors = error.response.data.errors;
          await this.showRequestError(error);
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
