<template>
  <comments-component
    :object="object"
    @onCommentsChanged="onCommentsChanged"
    :canAddComment="canAddComment"
  />
</template>
<script>
import { Button } from "element-ui";
import CommentsComponent from "@/components/Comments/CommentsComponent.vue";

export default {
  name: "property-comments",

  components: {
    CommentsComponent,
    [Button.name]: Button,
  },

  props: {
    object: {
      type: Object,
      required: true,
      description: "Comments linked object",
    },
    canAddComment: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },
  created() {},

  methods: {
    onCommentsChanged(comment) {
      this.$emit("onCommentsChanged", comment);
    },
  },

  watch: {
  },
};
</script>

<style lang="scss" scoped></style>