<template>
  <div class="elite-tabs-wrapper-content">
    <div class="mb-2">
      <h3>
        {{ $t("PROPERTIES.PAYMENT_DETAILS") }}
      </h3>
    </div>
    <div class="custom-header">
      <div class="mt-2">
        {{ $t("PROPERTIES.PROPERTY_TOTAL_DUE") }} :
        {{ $formatCurrency(property.total) }}
        <br />
        {{ $t("PROPERTIES.PROPERTY_TOTAL_PAIED") }} :
        {{ $formatCurrency(property.total_paied) }}
        <br />
        <span v-if="property.total_paied > property.total">
          {{ $t("PROPERTIES.PROPERTY_TOTAL_REFUND") }} :
          {{ $formatCurrency(property.total_paied-property.total) }}  
        </span>
        
      </div>

      <base-button
        type="button"
        @click="openModal"
        v-if="$currentUserCan($permissions.PERM_CREATE_PROPERTIES_PAYMENTS)"
        class="elite-button add"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("PROPERTY_TRANSACTIONS.ADD_TRANSACTION") }}
        </span>
      </base-button>

      <base-button
        type="button"
        @click="print"
        :disabled="loading"
        class="elite-button add"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-else class="btn-inner--icon">
          <i class="far fa-print"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("PROPERTIES.PRINT_RECEIPT") }}
        </span>
      </base-button>
    </div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <properties-payment-property-list-table
          :key="renderKey"
          :filterProperty="property.id"
        />
      </div>
    </div>
    <property-transaction-form
      :property="property"
      :showModal="showModal"
      @onCloseModal="closeModal"
    />
  </div>
</template>

<script>
import { Button, Table, TableColumn, Tooltip } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PropertyTransactionForm from "./PropertyTransactionForm";
import swal from "sweetalert2";
import PropertiesPaymentPropertyListTable from "../partials/PropertiesPaymentPropertyListTable.vue";

export default {
  name: "property-transaction-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    PropertyTransactionForm,
    PropertiesPaymentPropertyListTable,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
    property: {
      type: Object,
      default: null,
      description: "Property",
    },
    refreshView: {
      type: Function,
      default: null,
      description: "View refresh after some operation",
    },
  },

  data() {
    return {
      loading: false,
      showModal: false,
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    closeModal(refresh) {
      this.showModal = false;
      if (refresh) {
        this.renderKey++;
      }
    },

    openModal() {
      this.showModal = true;
    },

    async print() {
      this.loading = true;
      swal.fire({
        title: this.$t("COMMON.LOADING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "properties/print",
          this.property.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
        this.loading = false;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        swal.close();
        this.loading = false;
      }
    },
  },

  watch: {},
};
</script>
