<template>
  <div class="form-group taxes-selector">
    <label class="form-control-label"> {{ label }} </label>
    <div
      v-for="(batchNumber, key) in batchNumbersModel"
      :key="key"
      class="taxes-wrapper"
    >
      <div class="form-group-item name">
        <base-input
          :label="`${$t('PROPERTIES.BATCH')} ${key + 1}`"
          :placeholder="`${$t('PROPERTIES.BATCH')} ${key + 1}`"
          v-model="batchNumber.value"
          @change="batchNumbersChanged"
        />
      </div>

      <div class="remove-taxes-button">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-danger"
          @click.prevent="() => removeBatchNumber(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button type="button" class="btn add" @click.prevent="addBatchNumber">
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  name: "batch-numbers-inputs",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    batchNumbers: {
      type: Array,
      default: null,
      description: "Batch numbers array",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      batchNumbersModel: this.batchNumbers,
    };
  },

  setup() {},

  created() {},

  methods: {
    addBatchNumber() {
      this.batchNumbersModel.push({ value: null });
      this.batchNumbersChanged();
    },
    removeBatchNumber(key) {
      this.batchNumbersModel = this.batchNumbersModel.filter(
        (_, idx) => idx !== key
      );
      this.batchNumbersChanged();
    },
    batchNumbersChanged() {
      const batchNumbersData = this.batchNumbersModel.map((item) => {
        return item;
        /*
        const value = parseFloat(item.value);
        return {
          value: value ? value : null,
        };
         */
      });
      this.$emit("batchNumbersChanged", batchNumbersData);
    },
  },

  watch: {
    batchNumbers(batchNumbers) {
      this.batchNumbersModel = cloneDeep(batchNumbers);
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.taxes-selector {
    > label {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
    }

    .remove-taxes-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 43px;
          height: 43px;
          border-radius: 8px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }

    button {
      &.add {
        width: 43px;
        height: 43px;
        border-radius: 8px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  .taxes-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
