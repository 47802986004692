<template>
  <div class="">
    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.PAYMENT_OPERATION") }}
      </h3>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper full">
          <base-input
            :label="`${$t('PROPERTIES_PAYMENTS.ATTENTION')} (*)`"
            :placeholder="$t('PROPERTIES_PAYMENTS.ATTENTION')"
            v-model="propertiesPaymentsModel.attention"
            type="text"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.customer" />
        </div>
        <div class="form-wrapper full">
          <property-payment-inputs
            @transactionsChanged="
              (paymentsData) => {
                paymentsModel = paymentsData;
              }
            "
          />
        </div>

        <div class="form-wrapper full">
          <base-input
            :label="`${$t('PROPERTIES_PAYMENTS.DATE')} (*)`"
            :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
            type="number"
          >
            <flat-picker
              :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                maxDate: 'today',
                locale: $flatPickrLocale(),
                disable: [
                  function (date) {
                    return date.getDay() === 0;
                  },
                ],
              }"
              class="form-control datepicker"
              v-model="propertiesPaymentsModel.date"
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.date" />
        </div>

        <div class="form-wrapper full">
          <base-input
            :label="$t('PROPERTIES_PAYMENTS.EXCERPT')"
            :placeholder="$t('PROPERTIES_PAYMENTS.EXCERPT')"
          >
            <html-editor
              v-model="propertiesPaymentsModel.excerpt"
              @change="onFormChanged()"
            >
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="handleSubmit"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.SAVE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import PropertyPaymentInputs from "@/components/PropertyPaymentInputs.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultPropertiesPayment from "../../PropertiesPaymentManagement/defaultPropertiesPayment";

export default {
  name: "property-transaction-form",

  components: {
    ValidationError,
    PropertyPaymentInputs,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
    HtmlEditor,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["property", "showModal", "formErrors"],

  data() {
    let propertiesPaymentData = cloneDeep(defaultPropertiesPayment);
    propertiesPaymentData.organization = this.property.organization;
    return {
      loading: false,
      propertiesPaymentsModel: propertiesPaymentData,
      paymentsModel: [],
    };
  },

  computed: {},

  methods: {
    async handleSubmit() {
      this.loading = true;
      let paymentsData = cloneDeep(this.paymentsModel);
      paymentsData = paymentsData.filter(
        (item) => !!item.amount && item.source
      );

      try {
        for (const payment of paymentsData) {
          const propertiesPaymentData = cloneDeep(this.propertiesPaymentsModel);
          propertiesPaymentData.amount = payment.amount;
          propertiesPaymentData.source = payment.source;
          delete propertiesPaymentData.propertiesPaymentsProperties;

          await this.$store.dispatch(
            "propertiesPayments/add",
            propertiesPaymentData
          );
          const propertiesPayment =
            this.$store.getters["propertiesPayments/propertiesPayment"];

          await this.$store.dispatch("propertiesPaymentsProperties/add", {
            type: "properties-payments-properties",
            amount: payment.amount,
            property: this.property,
            propertiesPayment: propertiesPayment,
            relationshipNames: ["property", "propertiesPayment"],
          });
        }

        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES_PAYMENTS.PROPERTIES_PAYMENT_ADDED"),
        });
        this.loading = false;
        this.closeModal(true);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },
  },

  mounted() {},

  watch: {},
};
</script>
