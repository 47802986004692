import {
  SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
  PAYMENT_STATUS_COMPLETED,
} from "@/constants/propertiesPayment";

export default {
  type: "properties-payments",
  relationshipNames: ["organization", "propertiesPaymentsProperties"],
  organization: {
    type: "organizations",
    id: null,
  },
  propertiesPaymentsProperties: [],
  amount: 0,
  attention: '',
  excerpt: "",
  status: PAYMENT_STATUS_COMPLETED,
  source: SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
};
