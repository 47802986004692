import { TRANSACTION_TYPE_PAYMENT } from "@/constants/properties";

export default {
  type: "bids",
  ip_address: "",
  bid: "",
  max_bid: "",
  relationshipNames: ["property", "user", "createdBy"],
  property: {
    type: "properties",
    id: null,
  },
  user: {
    type: "users",
    id: null,
  },
  createdBy: {
    type: "users",
    id: null,
  },
};
