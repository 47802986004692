<template>
  <div class="estimate-comments-item-line">
    <div class="estimate-comments-item-line-header">
      <ul>
        <li class="icon">
          <i class="fal fa-user"></i>
        </li>
        <li class="name"><user :user="comment.creator" /></li>
        <li class="post-time">({{ $tzTimeAgo(comment.created_at) }})</li>
      </ul>
    </div>
    <div class="estimate-comments-item-line-content scrollspy-target">
      <a
        class="bg-lighter mt-3 mb-2 p-3 border-left-4px text-sm d-block"
        v-if="!!comment.replyTo"
        :href="`#scrollspy-${comment.replyTo?.id}`"
        v-html="comment.replyTo.short_comment"
      />
      <div v-html="comment.comment" class="ml-0" />
      <comment-view-attachements :comment="comment" />
      <button @click.prevent="reply" v-if="!hiddenReply">
        <i class="far fa-reply"></i>
      </button>
    </div>
  </div>
</template>

<script>
import User from "@/components/User.vue";
import CommentViewAttachements from "./CommentViewAttachements.vue";

export default {
  name: "comments-item-card",

  components: { 
    User,
    CommentViewAttachements,
  },

  props: {
    comment: {
      type: Object,
      required: true,
    },
    hiddenReply: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  created(){},

  methods: {
    reply() {
      this.$emit("onReply", this.comment);
    },
  },
};
</script>

<style lang="scss" scoped>
  
.estimate-comments-item-line {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px 12px;
  border-radius: 8px;
}
.estimate-comments-item-line.sub-line {
  width: calc(100% - 30px);
  position: relative;
  left: 30px;
  margin: 8px 0 0 0;
  background-color: rgba(var(--primary-rgb), 0.2);
}
.estimate-comments-item-line-header {
  width: 100%;
}
.estimate-comments-item-line-header ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.estimate-comments-item-line-header ul li {
  font-size: 13px;
  margin: 0 8px 0 0;
  font-weight: 400;
  color: #000000;
}
.estimate-comments-item-line-header ul li:last-child {
  margin-right: 0;
}
.estimate-comments-item-line-header ul li.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.estimate-comments-item-line-header ul li.icon i {
  font-size: 13px;
}
.estimate-comments-item-line-header ul li.name {
  font-weight: 600;
}
.estimate-comments-item-line-header ul li.post-time {
  color: #5f5f5f;
}
.estimate-comments-item-line-content {
  width: 100%;
  margin: 5px 0 0 0;
  padding: 0 40px 0 0;
  position: relative;
}
.estimate-comments-item-line-content p {
  margin: 0 0 0 38px;
  color: #000000;
}
.estimate-comments-item-line-content button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--primary-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
}
.estimate-comments-item-line-content button i {
  color: #000;
  font-size: 13px;
}
</style>
