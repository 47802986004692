<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <div class="row">
          <div class="col-12 text-right">
            <base-button
              type="button"
              @click="openCreateCommunicationModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_COMMUNICATIONS)"
              class="elite-button add"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMUNICATIONS.ADD_COMMUNICATION") }}
              </span>
            </base-button>
          </div>
        </div>
    </div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="!loading ? communications : []"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column
              :label="$t('COMMUNICATIONS.COMMUNICATION_TYPE')"
              prop="communication_type"
              sortable="custom"
              min-width="120"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.communication_type }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMUNICATIONS.TRAKING_NUMBER')" min-width="220">
              <template v-slot="{ row }">
                <span>
                  {{ row.tracking_number }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMUNICATIONS.DATE')" min-width="220">
              <template v-slot="{ row }">
                <span>
                  {{ $formatDate(row.date) }}
                </span>
              </template>
            </el-table-column>


            <el-table-column min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_COMMUNICATIONS)"
                >
                  <a
                    type="text"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <router-link :to="$objectViewRoute(row)">
                      <i class="fal fa-expand-alt"></i>
                    </router-link>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div
      v-if="showCreateCommunicationModal"
      class="resizable-wrapper"
      v-bind:class="[showCreateCommunicationModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'COMMUNICATION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("COMMUNICATIONS.ADD_COMMUNICATION") }}
          </h1>
          <button class="close" @click="closeCreateCommunicationModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <add-communication-component
            :property="property"
            @communicationCreated="communicationCreated($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import Users from "@/components/Users.vue";
import defaultCommunication from "../../../AuctionsModule/CommunicationManagement/defaultCommunication";
import AddCommunicationComponent from "@/views/Pages/AuctionsModule/CommunicationManagement/components/AddCommunicationComponent.vue";

export default {
  name: "property-communication-list-table",

  components: {
    BasePagination,
    //OrganizationSelector,
    //Users,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    AddCommunicationComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterProperty: {
      type: String,
      default: null,
      description: "Property id",
    },
    property: {
      type: Object,
      default: null,
      description: "Property",
    },
  },

  data() {
    const communicationData = this.$fillUserOrganizationData(defaultCommunication);
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      communications: [],
      loading: true,
      selectedOrganization: null,
      showCreateCommunicationModal: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterProperty: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "",
        };

        if (this.filterProperty) {
          params = {
            ...params,
            filter: { ...params.filter, property: this.filterProperty },
          };
        }

        await this.$store.dispatch("communications/list", params);
        this.communications = this.$store.getters["communications/list"];
        this.total = this.$store.getters["communications/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteCommunication(communication) {
      this.$emit("onDeleteCommunication", communication);
    },

    viewCommunication(communication) {
      this.$objectViewRoute(communication);
    },

    editCommunication(communication) {
      this.$emit("onEditCommunication", communication);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    openCreateCommunicationModal(){
      this.showCreateCommunicationModal = true;
    },
    closeCreateCommunicationModal(){
      this.showCreateCommunicationModal = false;
    },
    communicationCreated(refresh){
      this.showCreateCommunicationModal = false;
      this.$emit("onCommunicationCreated");
    },
  },
};
</script>
