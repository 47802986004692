<template>
  <div class="form-group taxes-selector">
    <label class="form-control-label"> {{ label }} </label>
    <div
      v-for="(transaction, key) in transactionsModel"
      :key="key"
      class="taxes-wrapper"
    >
      <div class="form-group-item row name">
        <div class="col-6">
          <base-input
            :label="`${$t('COMMON.AMOUNT')} (*)`"
            :placeholder="$t('COMMON.AMOUNT')"
            v-model="transaction.amount"
            type="number"
            @change="transactionsChanged"
          >
          </base-input>
        </div>
        <div class="col-6">
          <base-input
            :label="`${$t('PROPERTIES_PAYMENTS.SOURCE')} (*)`"
            :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
          >
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
              v-model="transaction.source"
              @change="transactionsChanged"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PROPERTIES_PAYMENTS_SOURCES"
                :key="key"
                :label="$t(`PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>

      <div class="remove-taxes-button">
        <base-button
          type="button"
          class="form-control btn btn-danger"
          :disabled="transactionsModel?.length === 1"
          @click.prevent="() => removeTransaction(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button
      :disabled="currentTotal >= parseFloat(property?.total).toFixed(2)"
      type="button"
      class="btn add"
      @click.prevent="addTransaction"
    >
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { PROPERTIES_PAYMENTS_SOURCES } from "@/constants/propertiesPayment";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  name: "property-payment-inputs",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    property: {
      type: [Object],
      default: null,
      description: "Property",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      transactionsModel: [],
      PROPERTIES_PAYMENTS_SOURCES,
    };
  },

  setup() {},

  mounted() {},

  created() {},

  computed: {
    currentTotal() {
      return this.transactionsModel.reduce(
        (sum, item) => sum + parseFloat(item.amount),
        0
      );
    },
  },

  methods: {
    async addTransaction() {
      this.transactionsModel.push({
        amount: 0,
        source: null,
      });
      this.transactionsChanged();
    },

    removeTransaction(key) {
      this.transactionsModel = this.transactionsModel.filter(
        (_, idx) => idx !== key
      );
      this.transactionsChanged();
    },

    transactionsChanged() {
      const transactionsData = this.transactionsModel.map((item) => {
        return { ...item, amount: parseFloat(item.amount) };
      });
      this.$emit("transactionsChanged", transactionsData);
    },
  },

  watch: {},
};
</script>

<style lang="scss">
.form-group {
  &.taxes-selector {
    > label {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
    }

    .remove-taxes-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 43px;
          height: 43px;
          border-radius: 8px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }

    button {
      &.add {
        width: 43px;
        height: 43px;
        border-radius: 8px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  .taxes-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
